import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Content from '../views/Content.vue'
import Collect from '../views/Collect.vue'
import Setting from '../views/Setting.vue'
import Signin from '../views/Signin.vue'
import Editor from '../views/Editor.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      requiresAuth: true,
      // forGuest: true,
    },
  },
  {
    path: '/collect',
    name: 'Collect',
    component: Collect,
    meta: {
      title: '网站采集',
      requiresAuth: true,
      // forGuest: true,
    },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    meta: {
      title: '设置',
      requiresAuth: true,
      // forGuest: true,
    },
  },
  {
    path: '/content/:id(\\d+)',
    name: 'Content',
    component: Content,
    meta: {
      title: '纠错页',
      requiresAuth: true,
      // forGuest: true,
    },
  },
  {
    path: '/editor/:id(\\d+)',
    name: 'Editor',
    component: Editor,
    meta: {
      title: '编辑页',
      requiresAuth: true,
      // forGuest: true,
    },
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: {
      title: '登录页',
      forGuest: true,
    },
  }
]

const router = new VueRouter({
  routes
})

/**
 * 路由守卫 登录检测 islogin
 *
 * 需要登录的页面路由需要添加
 * 登录后不可访问的路由需要添加
 * meta.forGuest = true
 *
 */
 router.beforeEach((to, from, next) => {
  const logged = !!window.$lstore.hasData('H5_ACCESS_TOKEN')
  const forGuest = to.matched.some(record => record.meta.forGuest)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (logged) {
    forGuest ? next({ path: '/' }) : next()
  } else {
    forGuest
      ? next()
      : requiresAuth
        ? next({ path: '/signin', query: { redirect: to.fullPath } })
        : next()
  }
})

export default router
