<template>
    <div class="signin-box">
        <div class="signin-bg"></div>
        <div class="signin">
            <div class="signincontent">
                <h2 class="signincontent-h2">登录</h2>
                <el-form class="signin-from">
                    <el-form-item prop="account">
                        <el-input v-model="account" placeholder="请输入账号" class="signin-input"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="password" placeholder="请输入密码" class="signin-input" type="password"></el-input>
                    </el-form-item>
                    <el-form-item class="tc">
                        <el-button type="primary" class="submit-button" @click="login" :loading="loading">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="icp">
          <span>@Copyright 南宁星岚网络科技有限公司</span>
          <a target="_blank" href="https://beian.miit.gov.cn/">桂ICP备19000795号-2</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Signin",
        data() {
            return {
                loading: false,
                account: "",
                password: ""
            };
        },
        methods: {
            login() {
                let that = this
                if(that.loading){
                    return false
                }
                that.loading = true
                if(that.account=='' || that.password==''){
                    that.$message.error('账号或密码不能为空');
                    that.loading = false
                    return false;
                }
                that.$api.post('/user/login', {
                    account: that.account,
                    password: that.password
                }).then(function (response) {
                    that.loading = false
                    if(response.data.code){
                        that.$message.success(response.data.msg);
                        that.$lstore.setData('H5_ACCESS_TOKEN', response.data.data.token)
                        that.$lstore.setData('H5_CURRENT_USER', response.data.data)
                        that.$nextTick(() => {
                            const redirect = that.$route.query.redirect
                            that.$router.push(redirect || '/')
                        })
                    }else{
                        that.$message.error(response.data.msg);
                    }
                }).catch(function (error) {
                    console.log(error);
                    that.loading = false
                });
            },
        }
    };
</script>

<style scoped>
.signin {
    width: 400px;
    height: auto;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    top: 40%;
}
.signincontent {
    width: 400px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
}
.signincontent-h2 {
    margin-top:0;
    text-align: center;
    font-size: 28px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #000000;
    line-height: 37px;
    letter-spacing: 1px;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.signin-from {
    margin-top: 30px;
}
.submit-button {
    width: 100%;
}
.signin-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/images/login-bg.png") no-repeat;
    background-size: 1920px 1080px;
    /* background-size: cover; */
    background-position: center center;
}
.icp{
    width:540px;
    z-index:999;
    position: fixed;
    bottom:20px;
    left:calc(50% - 200px);
    font-size:16px;
}
.icp span{
  margin-right:20px;
}
.icp a{
  color:#2c3e50;
  /* text-decoration:none; */
}
</style>
