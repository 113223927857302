<template>
  <div class="container">
    <el-container>
      <el-aside width="100px">
        <Menu :current_menu="1" />
      </el-aside>
      <el-main>
        <div class="doc-list">
          <div class="upload-demo">
            <div class="create-new" @click="createDoc">
              <img :src="require('@/assets/images/upload.png')" alt="">
              <p>新建文档</p>
            </div>
            <el-upload
              class="upload-box"
              action=""
              accept=".docx,.DOCX"
              :show-file-list="false"
              :http-request="uploadDoc">
              <div class="upload-new">上传文档</div>
            </el-upload>
          </div>
          <div v-loading="loading" class="doc-item" @click="goContent(item.id)" v-for="(item, index) in list" :key="index" @mouseenter="item.hover=true" @mouseleave="item.hover=false">
            <div class="title desc">{{ item.title||'无标题文档' }}</div>
            <div class="number desc"><i class="el-icon-edit-outline"></i>字数：{{ item.number }}</div>
            <div class="number desc"><i class="el-icon-place"></i>来源：{{ item.cid>0 ? '网站采集' : '用户文档' }}</div>
            <div class="time desc"><i class="el-icon-time"></i>时间：{{ item.time }}</div>
            <div class="bottom" @click.stop>
              <div class="not-hover" v-if="!item.hover">
                <i v-if="item.error==0" class="el-icon-check"></i>
                <span v-else>{{ item.error }}</span>
              </div>
              <div class="has-hover" v-else>
                <i @click.stop="downloadDoc(item.id)" class="el-icon-download"></i>
                <i @click.stop="deleteDoc(item.id, item.title)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  name: "Home",
  components: {
    Menu,
  },
  data() {
    return {
      list: [],
      id: 0,
      title: '',
      loading: false,
    };
  },
  mounted () {
    // 获取文档列表
    this.getDocList()
  },
  methods: {
    // 下载文档
    downloadDoc(id) {
      let that = this
      if(that.loading){
        return false;
      }
      that.loading = true;
      that.$api.get('/index/downloadDoc', {
        params: {
          id: id,
        }
      }).then(function (response) {
        console.log(response)
        that.loading = false;
        if(response.data.code){
          location.href = response.data.data
        }
      }).catch(function (error) {
        that.loading = false;
        console.log(error);
      });
    },
    // 跳转内容页
    goContent(id) {
      this.$router.push('/content/'+id)
    },
    // 获取文档列表
    getDocList() {
      let that = this
      this.$api.get('/index/getDocList',).then(function (response) {
        console.log(response)
        that.list = response.data.data
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 创建文档
    createDoc() {
      let that = this
      that.$api.get('/index/createDoc',).then(function (response) {
        console.log(response)
        that.$router.push('/content/'+response.data.data)
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 删除文档
    deleteDoc(id, title){
      let that = this
      let tip = title ? title : '无标题文档';
      that.$confirm('您正在尝试删除文档 "'+tip+' ", 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$api.get('/index/deleteDoc', {
          params: {
            id: id,
          }
        }).then(function (response) {
          console.log(response.data.data)
          if(response.data.code){
            that.getDocList();
            that.$message.success(response.data.msg);
          }else{
            that.$message.error(response.data.msg);
          }
        }).catch(function (error) {
          that.$message.error(error);
        });
      });
    },
    // 上传文档
    uploadDoc(param) {
      let that = this
      const formData = new FormData()
      formData.append('file', param.file)
      that.$api.post('/index/createDoc', formData).then(function (response) {
        console.log(response)
        that.$router.push('/content/'+response.data.data)
      }).catch(function (error) {
        console.log(error);
      });
    },
  }
};
</script>

<style scoped>
  .bottom{
    padding-top:10px;
  }
  .has-hover{
    width:100%;
    border-top: 1px solid rgb(237, 237, 237);
    color:#2470ff;
    text-align: center;
  }
  .has-hover i{
    font-size:24px;
    margin:13px;
  }
  .not-hover{
    position: absolute;
    bottom:15px;
    right:20px;
  }
  .not-hover i{
    color:#32ED5E;
    font-size:24px;
    font-weight: bold;
  }
  .not-hover span{
    text-align: right;
    font-size: 24px;
    color: #2470ff;
    font-weight: 700;
  }
  .doc-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .doc-item{
    position: relative;
    width: 180px;
    height: 220px;
    background-color: #fff;
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 8%);
    border-radius: 8px;
    margin: 20px;
    cursor: pointer;
  }
  .doc-item .desc{
    text-align: left;
    margin-left: 18px;
    margin-right: 18px;
    font-size: 14px;
    line-height: 30px;
    color: #888;
  }
  .doc-item .title{
    font-size: 16px;
    height: 40px;
    color: #111;
    font-weight: 700;
    padding-top: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 1.3;
    margin-bottom: 10px;
  }
  .desc i{
    margin-right:5px;
    color:#2470FF;
    font-weight: bold;
  }
  .upload-demo{
    width: 400px;
    height: 220px;
    margin: 20px;
    border: 1px dashed #fff;
    box-shadow: 0 1px 7px 0 rgba(0,0,0,.08)!important;
    border-radius: 8px;
    background: #fff;
    text-align: center;
  }
  .create-new{
    padding:25px 0;
    color:#2470FF;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid rgb(237, 237, 237);
  }
  .create-new img{
    width:80px;
  }
  .upload-new{
    width:400px;
    height:60px;
    line-height:60px;
    cursor: pointer;
  }
</style>
