<template>
    <div class="editor">
        <TinymceEditor v-model="content" @input="getContent"/>
        <el-button class="button" type="primary" @click="handleContent">排版</el-button>
        <el-button class="button" type="primary" @click="downloadContent">下载</el-button>
    </div>
</template>

<script>
import TinymceEditor from "@/components/Editor.vue";
import $ from 'jquery'

export default {
    name: "Editor",
    components: {
        TinymceEditor
    },
    data() {
        return {
            id: 0,
            content: "",
            setting: {
                height: 700
            }
        };
    },
    mounted() {
        this.getDoc()
    },
    methods: {
        getContent(content){
            this.content = content
        },
        // 获取文档内容
        getDoc() {
            let that = this
            that.$api.get('/index/getDoc', {
                params: {
                    id: that.$route.params.id
                }
            }).then(function (response) {
                console.log(response)
                that.content = response.data.data.result.content
            }).catch(function (error) {
                that.$message.error(error);
            });
        },
        handleContent(){
            let that = this;
            that.dialogVisible = true;
            $.post("/api/index/handleContent", { id: that.$route.params.id, content: that.content }, function(response){
                console.log(response);
                that.content = response.data
            }, "json");
        },
        // 下载排版数据
        downloadContent() {
            let that = this;
            that.dialogVisible = true;
            that.$api.get("/index/downloadContent", {
                params: {
                    id: that.$route.params.id,
                    content: that.content
                }
            }).then(function(response) {
                console.log(response);
                location.href = response.data.data
                // that.$message.success(response.data.msg);
                // that.getDoc();
            }).catch(function(error) {
                that.$message.error(error);
            });
        },
        setup(editor) {
            console.log(editor)
        }
    }
};
</script>

<style scoped>
    .editor{margin:50px;text-align: center;}
    .button{margin-top:20px;}
</style>

