<template>
  <div id="textAreaTemplate">
    <!-- //富文本插件 -->

    <div class="tinymcebox">
      <tinymce-editor v-model="content" :init="init" :disabled="disabled"></tinymce-editor>
    </div>
  </div>
</template>

<script>
// import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
/* import "tinymce/icons/default/icons";
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/preview";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/advlist";
import "tinymce/plugins/codesample";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/charmap";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/autosave";
import "tinymce/plugins/autoresize"; */

export default {
  components: {
    "tinymce-editor": Editor
  },
  props:{
    value:{
      type:String,
      default:''
    }
  },
  watch:{
    value(newValue){
      this.content = newValue
    },
    content(newValue){
      this.$emit("input", newValue)
    }
  },
  data() {
    return {
      disabled: false,
      content: this.value,
      //初始化配置
      init: {
        _target: () => this,
        // selector: '#tinydemo',
        // inline: true,
        // menubar: true, // 菜单栏显隐
        language_url: "./tinymce/langs/zh-Hans.js",
        language: "zh-Hans",
        skin_url: "./tinymce/skins/ui/oxide",
        height: 700,
        min_height: 700,
        max_height: 700,
        toolbar_mode: "wrap",
        plugins:
          "preview searchreplace autolink directionality visualblocks visualchars fullscreen template code table charmap  nonbreaking insertdatetime advlist lists autosave autoresize indent2em",
        toolbar:
          "undo redo | cut copy paste pastetext | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify outdent indent2em formatpainter | \
    styleselect formatselect fontselect fontsizeselect | subscript superscript removeformat | \
    table charmap  pagebreak insertdatetime | fullscreen preview|",
        // content_style: "p {margin: 5px 0;}",
        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        branding: false,
        paste_data_images: true,
      },
    };
  },
  mounted() {
    // alert(this.content)
  },
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>

</style>
