<template>
    <div class="container">
        <el-container>
            <el-aside width="100px">
                <Menu :current_menu="3" />
            </el-aside>
            <el-main>
                <div class="form-box">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="个人信息" name="first">
                            <el-form ref="form_info" :model="form_info" label-width="80px">
                                <el-form-item 
                                    label="用户账号"
                                >
                                    <el-input v-model="data.username" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    label="有效期"
                                >
                                    <el-input v-model="data.validity_time_text" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    prop="nickname"
                                    label="使用人"
                                    :rules="[
                                        { required: true, message: '请输入使用人', trigger: 'blur' },
                                    ]"
                                >
                                    <el-input v-model="form_info.nickname"></el-input>
                                </el-form-item>
                                <el-form-item
                                    prop="email"
                                    label="电子邮箱"
                                    :rules="[
                                        { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                                    ]"
                                >
                                    <el-input v-model="form_info.email"></el-input>
                                </el-form-item>
                                <el-form-item label="邮箱通知">
                                    <el-switch v-model="form_info.notice"></el-switch>
                                </el-form-item>
                                <el-form-item 
                                    label="报告周期"
                                >
                                    <el-select v-model="form_info.notice_date" placeholder="请选择">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- <el-form-item 
                                    label="报告时间"
                                >
                                    <el-select v-model="form_info.notice_time" placeholder="请选择">
                                        <el-option
                                        v-for="item in times"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item> -->
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('form_info')">提交</el-button>
                                    <el-button type="success" @click="testEmailNotice">邮件测试</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="修改密码" name="second">
                            <el-form ref="form_password" :model="form_password" label-width="80px">
                                <el-form-item 
                                    label="旧密码" 
                                    prop="oldpassword"
                                    :rules="[
                                        { required: true, message: '请输入旧密码', trigger: 'blur' },
                                    ]"
                                >
                                    <el-input type="password" v-model="form_password.oldpassword" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    label="新密码" 
                                    prop="newpassword"
                                    :rules="[
                                        { required: true, message: '请输入新密码', trigger: 'blur' },
                                        { validator: validatePass, trigger: 'blur' }
                                    ]"
                                >
                                    <el-input type="password" v-model="form_password.newpassword" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    label="确认密码" 
                                    prop="repassword"
                                    :rules="[
                                        { required: true, message: '请输入确认密码', trigger: 'blur' },
                                        { validator: validatePass2, message: '两次输入密码不一致', trigger: 'blur' }
                                    ]"
                                >
                                    <el-input type="password" v-model="form_password.repassword" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('form_password')">提交</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import Menu from "@/components/Menu.vue";

    export default {
        name: "Setting",
        components: {
            Menu,
        },
        data() {
            return {
                validatePass: (rule, value, callback) => {
                    if (value !== '' && this.form_password.repassword !== '') {
                        this.$refs.form_password.validateField('repassword');
                    }
                    callback();
                },
                validatePass2: (rule, value, callback) => {
                    if (value !== this.form_password.newpassword) {
                        callback(new Error('两次输入密码不一致!'));
                    }else{
                        callback();
                    }
                },
                form_info: {
                    username: '',
                    nickname: '',
                    email: '',
                    notice: false,
                    notice_date: 0,
                    notice_time: '',
                },
                form_password: {
                    oldpassword: '',
                    newpassword: '',
                    repassword: '',
                },
                activeName: 'first',
                data: [],
                options: [{
                    value: 0,
                    label: '每天'
                }, {
                    value: 1,
                    label: '每周'
                }, {
                    value: 2,
                    label: '每月'
                }],
                value: 0
            };
        },
        mounted () {
            // 获取用户信息
            this.data = this.$lstore.getData('H5_CURRENT_USER')
            this.form_info.username = this.data.username
            this.form_info.nickname = this.data.nickname
            this.form_info.email = this.data.email
            this.form_info.notice = this.data.notice==1 ? true : false
        },
        methods: {
            // 测试邮件
            testEmailNotice() {
                let that = this
                if(that.form_info.email==''){
                    that.$message.error('请先填写电子邮箱');
                    return false;
                }
                if(that.form_info.notice==false){
                    that.$message.error('请先开启邮件通知');
                    return false;
                }
                that.$confirm('是否确定测试邮件？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.$api.post('/index/testEmailNotice', that.form_info).then(function (response) {
                        // 刷新数据
                        if(response.data.code){
                            that.$message.success(response.data.msg);
                        }else{
                            that.$message.error(response.data.msg);
                        }
                    }).catch(function (error) {
                        that.$message.error(error);
                    });
                });
            },
            // 提交表单
            submitForm(formName) {
                let that = this
                that.$refs[formName].validate((valid) => {
                    if (valid) {
                        let form = [];
                        let url = '';
                        if(formName=='form_info'){
                            form = that.form_info
                            url = '/index/profile'
                        }else{
                            form = that.form_password
                            url = '/index/resetpwd'
                        }
                        that.$api.post(url, form).then(function (response) {
                            console.log(response)
                            if(response.data.code){
                                // 如果为修改密码，则需要重新登录
                                if(formName=='form_password'){
                                    that.$lstore.removeData('H5_ACCESS_TOKEN')
                                    that.$lstore.removeData('H5_CURRENT_USER')
                                    that.$router.push('/signin')
                                }else{
                                    that.$lstore.setData('H5_CURRENT_USER', response.data.data)
                                }
                                that.$message.success(response.data.msg);
                            }else{
                                that.$message.error(response.data.msg);
                            }
                        }).catch(function (error) {
                            that.$message.error(error);
                        });
                    }else{
                        that.$message.error('表单验证不通过，请检查数据是否填写完整');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .form-box{width:600px;margin:20px 40px;}
</style>