<template>
  <div class="container" v-loading="loading">
    <div class="header">
      <div class="header-left">
        <div class="back" @click="$router.go(-1)">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="score">剩余可检测字数：{{ score }} 个</div>
        <img v-loading="downloading" @click="downloadDoc()" class="export" :src="require('@/assets/images/export.png')" alt="">
        <div class="handle" @click="$router.push('/editor/'+$route.params.id)">
          <i class="el-icon-s-order"></i>
          <span>内容排版</span>
        </div>
        <div class="finish" @click="finishDoc">
          <i class="el-icon-s-check"></i>
          <span>人工校对无误</span>
        </div>
        <div class="save" @click="saveDoc">
          <i class="el-icon-success"></i>
          <span>检查</span>
        </div>
      </div>
    </div>
    <div class="page" id="page">
      <div class="page-left">
        <div class="title">
          <div class="title-box">
            <input class="title-input" type="text" :value="data.title">
          </div>
        </div>
        <div class="content">
          <div id="editorjs">
            <div class="codex-editor" contenteditable="true" v-html="result.content"></div>
          </div>
        </div>
      </div>
      <div class="page-right">
        <div class="alert-li" v-if="result.errors && result.errors.length>0">
          <div :class="'ue-alert-panel alertItemCollapseBorderLeft alertItemCollapseBorderLeft_'+item.errorType" v-for="(item, index) in result.errors" :key="index" @click="changeError(index)" v-show="current_error_tip==0 || item.errorType==current_error_tip">
            <div class="animation-wrapper">

              <div :class="current_error==index ? 'ue-alert-wrapper-left-active' : 'ue-alert-wrapper-left'">
                <div v-if="current_error==index">
                  <div style="margin-top: 8px; margin-bottom: 9px;">
                    <span class="alertItemExpandErrorType">{{ item.errorName }}</span> 
                    <i class="ue-point ue-point-xs"></i> 
                    <span class="alertItemExpandAlertType">{{ item.alertInfo }}</span>
                  </div> 
                  <div class="replaceNoticeContainer" @click.stop="doAmendError(index)">
                    <span class="deleteWords" v-if="item.alertType==3"> &nbsp;{{ item.sourceText }}&nbsp; </span>
                    <span class="replaceSourceText" v-else>{{ item.sourceText }}</span> 
                    <span class="alert-go-right" v-show="item.replaceText!=''"> &nbsp;&nbsp; </span> 
                    <span class="replaceText" v-show="item.replaceText!=''"> {{ item.replaceText }} </span>
                  </div> 
                  <div class="alertInfoContainer"> {{ item.alertMessage }}  </div>
                </div>
                <div v-else>
                  <div class="noticeIntro" style="line-height: 45px;">
                    <span class="alertItemCollapse sourceTextItemCollapse">{{ item.sourceText }}</span> 
                    <i class="ue-point ue-point-xs"></i> 
                    <span class="alertItemCollapseAlertType">{{ item.alertInfo }}</span> 
                    <span class="alertItemCollapse replaceTextItemCollapse">{{ item.replaceText }}</span>
                  </div>
                </div>
              </div>
              <div v-show="current_error!=index" class="ue-alert-wrapper-right"><img :src="require('@/assets/images/open.png')"></div>
              
            </div>
          </div>
        </div>
        <div class="ok" v-else>
          <img class="isok" :src="require('@/assets/images/isOk.png')" alt="">
          <p>检查完毕，一切都好</p>
        </div>
      </div>
    </div>
    <div class="right">
      <div :class="current_error_tip==0 ? 'all-tip js-tip on' : 'all-tip js-tip'" @click="changeErrorType(0)">
        <p>所有提示</p>
        <p class="number">{{ result.error }}</p>
      </div>
      <div class="line"></div>
      <div class="tip-list">
        <div :class="current_error_tip==index ? 'tip-item js-tip on' : 'tip-item js-tip'" v-for="(item, index) in result.tips" :key="index" @click="changeErrorType(index)">
          <span :class="'icon icon-'+index"></span>
          <p class="item-number">{{ item.count }}个</p>
          <p class="item-type">{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  export default {
    name: "Content",
    data() {
      return {
        id: 0,
        data: [],
        result: [],
        current_error: 0,
        current_error_tip: 0,
        loading: false,
        downloading: false,
        score: 0,
      };
    },
    mounted () {
      this.getDoc();
    },
    methods: {
      // 滚动到对应位置
      scrollPlace() {
        let that = this
        that.$nextTick(()=>{
          var h1 = $(".ue-alert-wrapper-left-active").offset();
          var h2 = $(".alert-li").offset();
          var height = h1.top-h2.top
          var offset = $(".JS_"+that.current_error).offset();
          $(".alert-li").animate({ 'margin-top': offset.top-height-110 }, 500);
          $("body,html").animate({ scrollTop: offset.top-($(window).height()/3)}, 500);
        })
      },
      // 人工校对无误
      finishDoc() {
        let that = this
        that.$api.get('/index/finishDoc', {
          params: {
            id: that.$route.params.id
          }
        }).then(function (response) {
          console.log(response)
          that.$message.success(response.data.msg);
          that.getDoc();
        }).catch(function (error) {
          that.$message.error(error);
        });
      },
      // 下载文档
      downloadDoc() {
        let that = this
        if(that.downloading){
          return false;
        }
        that.downloading = true;
        that.$api.get('/index/downloadDoc', {
          params: {
            id: that.data.id,
          }
        }).then(function (response) {
          console.log(response)
          that.downloading = false;
          if(response.data.code){
            location.href = response.data.data
          }
        }).catch(function (error) {
          that.downloading = false;
          console.log(error);
        });
      },
      // 修改错误
      doAmendError(index) {
        this.$nextTick(()=>{
          switch (this.result.errors[index].alertType) {
            case 3:
              $(".JS_"+index).remove();
              break;
            case 4:
              if(this.result.errors[index].errorType!=5 && this.result.errors[index].replaceText!=''){
                $(".JS_"+index).html(this.result.errors[index].replaceText).removeClass();
              }
              break;
          }
        })
      },
      // 切换错误
      changeError(error) {
        this.current_error = error
        // 设置当前错误文字高亮
        $('.JS_highlight').removeClass('JS_highlight');
        $('.JS_'+error).addClass('JS_highlight');
        // 获取对应的内容，并进行偏移
        this.scrollPlace();
      },
      // 切换错误类型
      changeErrorType(type) {
        $('.JS_highlight').removeClass('JS_highlight');
        this.current_error_tip = type
        if(type==0){
          this.current_error = 0
          $('.JS_0').addClass('JS_highlight');
        }else{
          // 重置默认打开错误
          for (let index = 0; index < this.result.errors.length; index++) {
            if(this.result.errors[index].errorType==type){
              this.current_error = index
              $('.JS_'+index).addClass('JS_highlight');
              break
            }
          }
        }
        this.scrollPlace();
      },
      // 保存文档信息
      saveDoc() {
        let that = this
        if(that.loading){
          return false;
        }
        that.loading = true;
        var title = $('.title-input').val();
        var content = $(".codex-editor").html();
        /* var content = '';
        $(".editor-block").each(function(){
          content += $(this).html()+'<br>'
        }); */
        that.$api.post('/index/saveDoc', {
          id: that.$route.params.id,
          title: title,
          content: encodeURI(content)
        }).then(function (response) {
          console.log(response)
          that.loading = false;
          if(response.data.code){
            that.data = response.data.data
            that.score = response.data.data.score
            that.result = response.data.data.result
            that.$message.success(response.data.msg);
          }else{
            that.$message.error(response.data.msg);
          }
        }).catch(function (error) {
          that.loading = false;
          that.$message.error(error);
        });
      },
      // 获取文档内容
      getDoc() {
        let that = this
        that.$api.get('/index/getDoc', {
          params: {
            id: that.$route.params.id
          }
        }).then(function (response) {
          console.log(response)
          that.data = response.data.data
          that.score = response.data.data.score
          that.result = response.data.data.result
          if(response.data.data.content){
            that.$nextTick(()=>{
              $('.JS_0').addClass('JS_highlight');
              that.scrollPlace();
            })
          }
        }).catch(function (error) {
          that.$message.error(error);
        });
      },
    }
  };
</script>

<style scoped>
  .codex-editor >>> p{
    text-indent: 2em;
    font-size:16px;
  }
  .ok{
    height: 450px;
    width: 350px;
    margin-top: 30%;
  }
  .ok img{
    width:100%;
  }
  .ok p{
    text-align: center;
    color: #2470ff;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .replaceNoticeContainer .deleteWords {
    background: #e71d3e;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    text-decoration: line-through;
  }
  .header-left{
    width:60%;
    position: relative;
  }
  .editor-block{
    padding: 0.4em 0;
    outline: none;
    min-height:32px;
    line-height:32px;
  }
  #editorjs {
    ---editor-padding-top: 10px;
    ---editor-padding-left: 55px;
    ---editor-padding-right: 55px;
  }
  .codex-editor {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: var(---editor-padding-top);
    padding-left: var(---editor-padding-left);
    padding-right: var(---editor-padding-right);
    background-color: #fff;
    -webkit-perspective: 3000px;
    perspective: 3000px;
    -webkit-perspective-origin: top;
    perspective-origin: top;
    width: 100%;
    margin: 0 auto;
    padding-bottom:300px;
    outline: none;
  }
  .alertInfoContainer {
    margin-bottom: 8px;
    margin-left: 26px;
    font-size: 14px;
    font-weight: 400;
    color: #111;
    margin-right: 10px;
  }
  .replaceNoticeContainer .replaceText {
    background: #d9e6ff;
    color: #2470ff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .alert-go-right, .alert-two-way {
    vertical-align: middle;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
  }
  .alert-go-right {
    background-image: url(https://uranus-static.oss-accelerate.aliyuncs.com/xiezuocat/go_right.png);
  }
  .replaceNoticeContainer .replaceSourceText, .replaceNoticeContainer .twoWayReplaceSourceText {
    font-size: 16px;
    font-weight: 400;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .replaceNoticeContainer {
    cursor: pointer;
    margin-left: 26px;
    margin-bottom: 9px;
    position: relative;
    display: inline-block;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: left;
    align-items: left;
    -ms-flex-line-pack: left;
    align-content: left;
    margin-right: 15px;
  }
  .alertItemExpandAlertType {
    font-size: 12px;
    font-weight: 400;
    color: #888;
  }
  .alertItemExpandErrorType {
    margin-left: 26px;
    font-size: 14px;
    font-weight: 400;
    color: #888;
  }
  .alertItemCollapseBorderLeft {
    position: relative;
    border-style: solid;
    border-width: 0 0 0 4px;
    border-radius: 4px;
  }
  .alertItemCollapseBorderLeft_1 {
    border-color: #ff7a7a;
  }
  .alertItemCollapseBorderLeft_2{
    border-color: #AAAAAA;
  }
  .alertItemCollapseBorderLeft_3{
    border-color: #977AFF;
  }
  .alertItemCollapseBorderLeft_5{
    border-color: #F6B5DD;
  }
  .alertItemCollapseBorderLeft_6{
    border-color: #F0CD6C;
  }
  .replaceTextItemCollapse {
    margin-left: 8px;
  }
  .alertItemCollapse {
    font-size: 16px;
    font-weight: 400;
    color: #111;
    display: inline-block;
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .alertItemCollapseAlertType {
    font-size: 12px;
    font-weight: 400;
    color: #888;
    vertical-align: middle;
  }
  .ue-point-xs {
    background-color: #8189a9;
    width: 2px;
    height: 2px;
  }
  .ue-point {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
  }
  .sourceTextItemCollapse {
    margin-left: 28px;
  }
  .alertItemCollapse {
    font-size: 16px;
    font-weight: 400;
    color: #111;
    display: inline-block;
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .alert-li {
    width: 100%;
    /* transition: margin-top .5s ease-in-out; */
  }
  .ue-alert-panel[data-expand-status="0"] .ue-alert-wrapper-right {
    display: none;
  }
  .ue-alert-wrapper-right {
    padding-right: 7px;
    -ms-flex-align: center;
    align-items: center;

    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .ue-alert-wrapper-right img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    border: 0;
  }
  .ue-alert-wrapper-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: calc(100% - 31px);
  }
  .animation-wrapper {
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      overflow: hidden;
  }
  .ue-alert-panel{
    padding: 8px;
    margin-bottom: 20px;
    font-family: \\5FAE\8F6F\96C5\9ED1,Helvetica,Arial,sans-serif;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: row;
    display: flex;
    background: #fff;
    box-shadow: 0 3px 12px 0 rgb(198 203 222 / 45%);
    cursor: pointer;
  }
  .codex-editor{
    background-color: transparent!important;
    position: relative;
    box-sizing: border-box;
    padding-top: var(---editor-padding-top);
    padding-left: var(---editor-padding-left);
    padding-right: var(---editor-padding-right);
    perspective: 3000px;
    perspective-origin: top;
    width: 100%;
    margin: 0 auto;
  }
  #editorjs{
    white-space: break-spaces;
    font-size: 10.5pt;
    text-align: left;
  }
  .content{
    outline: none;
    min-height: 747px;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
    line-height: 32px;
    letter-spacing: 1px;
    margin-top: 0;
    font-family: akkurat;
    margin-bottom: 0;
  }
  .page-left .title .title-input{
    border: 0;
    border-radius: 0;
    font-size: 24px;
    font-weight: 600;
    height: 36px;
    line-height: 36px;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
  }
  .page-left .title .title-box{
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #e8ecf1;
  }
  .page-left .title{
    padding: 40px 40px 0;
    border-radius: 8px;
    position: relative;
  }
  .page{
    width: calc(100% - 130px);
    margin-left: 20px;
    margin-top: 50px;
    display: -ms-flexbox;
    display: flex;
    height:100%;
    position: relative;
  }
  .page-left{
    border-radius: 4px;
    min-width: 60%;
    max-width: 60%;
    min-height: 800px;
    background-color: #fff;
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 8%);
    margin-left: 5px;
  }
  .page-right{
    padding-left: 20px;
    padding-right: 5px;
    user-select: none;
    display: flex;
    justify-content: center;
    background: #f8f8f8;
    width: 50%;
  }
  .icon{
    position: absolute;
    left:10px;
    top:25px;
    width:15px;
    height:15px;
    border-radius: 50%;
  }
  .icon-1{
    background: #FF7A7A;
  }
  .icon-2{
    background: #AAAAAA;
  }
  .icon-3{
    background: #977AFF;
  }
  .icon-5{
    background: #F6B5DD;
  }
  .icon-6{
    background: #F0CD6C;
  }
  .codex-editor >>> .JS_ERROR_1{
    padding-bottom:2px;
    border-bottom: 3px solid #FF7A7A;
  }
  .codex-editor >>> .JS_ERROR_1.JS_highlight{
    background-color:#FF7A7A;
    animation:black_1 1000ms infinite;
    -webkit-animation:black_1 1000ms infinite;
  }
  @keyframes black_1 {
    from { background-color:#fff; }
    to { background-color:#FF7A7A; }
  }
  @-webkit-keyframes black_1 {
    from { background-color:#fff; }
    to { background-color:#FF7A7A; }
  }
  .codex-editor >>> .JS_ERROR_2{
    border-bottom: 3px solid #AAAAAA;
  }
  .codex-editor >>> .JS_ERROR_2.JS_highlight{
    background-color:#AAAAAA;
    animation:black_2 1000ms infinite;
    -webkit-animation:black_2 1000ms infinite;
  }
  @keyframes black_2 {
    from { background-color:#fff; }
    to { background-color:#AAAAAA; }
  }
  @-webkit-keyframes black_2 {
    from { background-color:#fff; }
    to { background-color:#AAAAAA; }
  }
  .codex-editor >>> .JS_ERROR_3{
    border-bottom: 3px solid #977AFF;
  }
  .codex-editor >>> .JS_ERROR_3.JS_highlight{
    background-color:#977AFF;
    animation:black_3 1000ms infinite;
    -webkit-animation:black_3 1000ms infinite;
  }
  @keyframes black_3 {
    from { background-color:#fff; }
    to { background-color:#977AFF; }
  }
  @-webkit-keyframes black_3 {
    from { background-color:#fff; }
    to { background-color:#977AFF; }
  }
  .codex-editor >>> .JS_ERROR_5{
    border-bottom: 3px solid #F6B5DD;
  }
  .codex-editor >>> .JS_ERROR_5.JS_highlight{
    background-color:#F6B5DD;
    animation:black_5 1000ms infinite;
    -webkit-animation:black_5 1000ms infinite;
  }
  @keyframes black_5 {
    from { background-color:#fff; }
    to { background-color:#F6B5DD; }
  }
  @-webkit-keyframes black_5 {
    from { background-color:#fff; }
    to { background-color:#F6B5DD; }
  }
  .codex-editor >>> .JS_ERROR_6{
    border-bottom: 3px solid #F0CD6C;
  }
  .codex-editor >>> .JS_ERROR_6.JS_highlight{
    background-color:#F0CD6C;
    animation:black_6 1000ms infinite;
    -webkit-animation:black_6 1000ms infinite;
  }
  @keyframes black_6 {
    from { background-color:#fff; }
    to { background-color:#F0CD6C; }
  }
  @-webkit-keyframes black_6 {
    from { background-color:#fff; }
    to { background-color:#F0CD6C; }
  }
  .tip-item{
    height:70px;
    position: relative;
    padding-left:30px;
    padding-top:10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .tip-item.on{
    background: #D9E6FF;
  }
  .all-tip.on{
    background: #D9E6FF;
  }
  .item-type{
    font-size:14px;
    color:#888;
  }
  .item-number{
    font-size:18px;
    color:#111;
  }
  .line{
    width: 48px;
    height: 1px;
    background: rgba(0,0,0,.08);
    margin: 10px 0 10px 24px;
  }
  .right .all-tip{
    color:#111;
    text-align: center;
    font-size:14px;
    margin-top:30px;
    padding:20px 0;
    cursor: pointer;
  }
  .right .all-tip .number{
    font-size:30px;
    margin-top:10px;
    font-weight:bold;
  }
  .header{
    display: flex;
    justify-content: space-between;
    height: 50px;
    position: fixed;
    z-index: 2000;
    top: 0;
    width: 100%;
    background-color: #f8f8f8;
  }
  .back{
    background: #fff;
    position: fixed;
    top: 9px;
    height: 30px;
    width: 40px;
    left: 25px;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgb(96 101 123 / 20%);
    text-align: center;
    line-height:35px;
    cursor: pointer;
  }
  .back i{
    font-size:20px;
  }
  .save{
    background: #fff;
    position: absolute;
    top: 9px;
    height: 30px;
    width: 80px;
    right: 55px;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgb(96 101 123 / 20%);
    text-align: center;
    line-height:35px;
    cursor: pointer;
  }
  .save span{
    font-size:18px;
  }
  .save i{
    font-size:20px;
    margin-right:5px;
  }
  .finish{
    background: #fff;
    position: absolute;
    top: 9px;
    height: 30px;
    width: 160px;
    right: 150px;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgb(96 101 123 / 20%);
    text-align: center;
    line-height:35px;
    cursor: pointer;
  }
  .finish span{
    font-size:18px;
  }
  .finish i{
    font-size:20px;
    margin-right:5px;
  }
  .handle{
    background: #fff;
    position: absolute;
    top: 9px;
    height: 30px;
    width: 120px;
    right: 320px;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgb(96 101 123 / 20%);
    text-align: center;
    line-height:35px;
    cursor: pointer;
  }
  .handle span{
    font-size:18px;
  }
  .handle i{
    font-size:20px;
    margin-right:5px;
  }
  .score{
    position: absolute;
    align-items: center;
    right: 520px;
    top: 15px;
    display: flex;
    height: 20px;
    cursor: pointer;
  }
  .export{
    position: absolute;
    align-items: center;
    right: 460px;
    top: 15px;
    display: flex;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .right{
    position: fixed;
    top: 0;
    right: 0;
    width: 96px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 3px 12px 0 rgb(198 203 222 / 45%);
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 4001;
  }
</style>
