<template>
    <div class="container">
        <el-container>
            <el-aside width="100px">
                <Menu :current_menu="2" />
            </el-aside>
            <el-main>
                <div class="table-box">
                    <div class="table-header">
                        <div class="refresh" @click="refresh"><i class="el-icon-refresh"></i></div>
                        <div class="check">
                            <el-dropdown @command="multipleCheckContent">
                                <span class="el-dropdown-link">
                                    批量检查<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="1">勾选的</el-dropdown-item>
                                    <el-dropdown-item command="2">所有未检查的</el-dropdown-item>
                                    <el-dropdown-item command="3">今天未检查的</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="search-time">
                            <el-date-picker
                                size="small"
                                v-model="time"
                                type="datetimerange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd hh:mm:ss"
                                :default-time="['00:00:00', '23:59:59']"
                            >
                            </el-date-picker>
                        </div>
                        <div class="search-status">
                            <el-select v-model="status" placeholder="请选择状态" size="small" :clearable="true">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="search-title">
                            <el-input v-model="title" placeholder="请输入标题" size="small"></el-input>
                        </div>
                        <div class="search-btn">
                            <el-button type="primary" @click="getCollectList" size="small">搜索</el-button>
                        </div>
                    </div>
                    <el-table
                        :data="list"
                        v-loading="loading"
                        @selection-change="handleSelectionChange">
                        <el-table-column
                            type="selection"
                            align="center"
                            width="55">
                        </el-table-column>
                        <el-table-column
                            prop="webname"
                            align="center"
                            label="网站">
                        </el-table-column>
                        <el-table-column
                            prop="catname"
                            align="center"
                            label="栏目">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="标题">
                            <template slot-scope="scope">
                                <el-link @click="goUrl(scope.row.url)" type="primary">{{scope.row.title}}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="状态">
                            <template slot-scope="scope">
                                <el-button
                                    :class="'operate-btn status'+scope.row.status"
                                    slot="reference"
                                    type="text"
                                    :disabled="scope.row.status==2 && scope.row.doc_id>0 ? false : true"
                                    @click="goContent(scope.row.doc_id)"
                                    size="small">
                                    {{ scope.row.status_text }}
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="publish_time"
                            align="center"
                            width="150"
                            label="发布时间">
                        </el-table-column>
                        <el-table-column
                            prop="create_time"
                            align="center"
                            width="150"
                            label="采集时间">
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            align="center"
                            label="操作">
                            <template slot-scope="scope">
                                <el-popconfirm
                                    title="是否确定重新采集内容？"
                                    @confirm="collectContent(scope.row.id)">
                                    <el-button
                                        class="operate-btn"
                                        slot="reference"
                                        type="text"
                                        size="small">
                                        重新采集
                                    </el-button>
                                </el-popconfirm>
                                <el-popconfirm
                                    :title="'需要消耗：'+scope.row.number+' 个字数，是否重新检测？'"
                                    v-if="scope.row.status!=0"
                                    @confirm="checkContent(scope.row.id)">
                                    <el-button
                                        class="operate-btn"
                                        slot="reference"
                                        type="text"
                                        size="small">
                                        重新检测
                                    </el-button>
                                </el-popconfirm>
                                <el-popconfirm
                                    title="是否确定删除当前内容？"
                                    @confirm="deleteContent(scope.row.id)">
                                    <el-button
                                        class="operate-btn"
                                        slot="reference"
                                        type="text"
                                        size="small">
                                        删除
                                    </el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        class="pagination"
                        v-if="list.length > 0"
                        @current-change="handleCurrentChange"
                        @size-change="handleSizeChange"
                        :current-page="page"
                        :page-sizes="[10, 20, 50, 100, 200, 500]"
                        :page-size="pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import Menu from "@/components/Menu.vue";
    
    export default {
        name: "Collect",
        components: {
            Menu,
        },
        data() {
            return {
                list: [],
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
                multipleSelection: [],
                options: [{
                    value: 0,
                    label: '未检查'
                }, {
                    value: 1,
                    label: '检查通过'
                }, {
                    value: 2,
                    label: '检查出问题'
                }, {
                    value: 3,
                    label: '人工校对无误'
                }],
                status: 2,
                time: [],
                title: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                        }
                    }]
                },
            };
        },
        mounted () {
            if(this.$route.query.length>0){
                this.time.push(this.$route.query.begin)
                this.time.push(this.$route.query.end)
                this.status = this.$route.query.status
            }
            // 获取采集内容列表
            this.getCollectList()
        },
        methods: {
            // 提交搜索
            submitSearch() {
                console.log(this.time)
            },
            // 批量检查
            multipleCheckContent(type){
                let that = this
                let ids = '';
                if(type==1){
                    if(that.multipleSelection.length == 0){
                        that.$message.error('未选中任何项');
                        return false;
                    }
                    // 获取选中项所有id
                    for (let index = 0; index < that.multipleSelection.length; index++) {
                        ids += that.multipleSelection[index].id+','
                    }
                }
                that.$api.post('/index/getCheckNumber',{
                    type: type,
                    ids: ids
                }).then(function (response) {
                    console.log(response.data.data)
                    if(response.data.code){
                        that.$confirm('需要消耗： '+response.data.data+' 个字数，是否确认检查？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            that.$api.post('/index/multipleCheckContent',{
                                type: type,
                                ids: ids
                            }).then(function (response) {
                                console.log(response.data.data)
                                // 刷新数据
                                that.getCollectList()
                                that.$message.success(response.data.msg);
                            }).catch(function (error) {
                                that.$message.error(error);
                            });
                        });
                    }else{
                        that.$message.error(response.data.msg);
                    }
                }).catch(function (error) {
                    that.$message.error(error);
                });
            },
            // 重新采集
            collectContent(id) {
                let that = this
                that.$api.post('/index/collectContent',{
                    id: id
                }).then(function (response) {
                    // 刷新数据
                    that.getCollectList()
                    that.$message.success(response.data.msg);
                }).catch(function (error) {
                    that.$message.error(error);
                });
            },
            // 重新检测
            checkContent(id) {
                let that = this
                that.$api.post('/index/checkContent',{
                    id: id
                }).then(function (response) {
                    // 刷新数据
                    that.getCollectList()
                    that.$message.success(response.data.msg);
                }).catch(function (error) {
                    that.$message.error(error);
                });
            },
            // 刷新数据
            refresh() {
                this.page = 1
                this.getCollectList()
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 删除采集内容
            deleteContent(id) {
                let that = this
                that.$api.post('/index/deleteContent',{
                    id: id
                }).then(function (response) {
                    // 刷新数据
                    that.getCollectList()
                    that.$message.success(response.data.msg);
                }).catch(function (error) {
                    that.$message.error(error);
                });
            },
            // 跳转采集内容详情页
            goUrl(url) {
                window.open(url);
            },
            // 跳转操作台
            goContent(id) {
                this.$router.push('/content/'+id)
            },
            // 处理当前页数
            handleSizeChange(val) {
                this.page = 1
                this.pagesize = val
                this.getCollectList();
            },
            // 处理改变当前页数
            handleCurrentChange(val) {
                this.page = val;
                this.getCollectList();
            },
            // 获取文档列表
            getCollectList() {
                let that = this
                if(that.loading){
                    return false;
                }
                that.loading = true;
                that.$api.post('/index/getCollectList',{
                    page: that.page,
                    pagesize: that.pagesize,
                    status: that.status,
                    time: that.time,
                    title: that.title
                }).then(function (response) {
                    console.log(response)
                    that.loading = false;
                    that.list = response.data.data.list
                    that.total = response.data.data.total
                }).catch(function (error) {
                    that.loading = false;
                    console.log(error);
                });
            },

        }
    }
</script>

<style scoped>
    .container{background-color: #fff;height:100%;overflow-y: scroll;}
    .table-box{padding:0 20px;}
    .table-header{margin-bottom:20px;border-bottom:1px solid rgb(237, 237, 237);padding:15px 0;position: relative;}
    .refresh{background: #fff;height: 30px;width: 40px;border-radius: 4px;box-shadow: 0 4px 8px 0 rgb(96 101 123 / 20%);text-align: center;line-height: 35px;cursor: pointer;}
    .refresh i{color:#2051CA;font-weight: bold}
    .operate-btn{margin-right:10px;}
    .pagination{text-align: center;margin-top:20px;}
    .check{
        background: #fff;
        position: absolute;
        top: 15px;
        height: 30px;
        width: 110px;
        left: 70px;
        z-index: 100;
        border-radius: 4px;
        box-shadow: 0 4px 8px 0 rgb(96 101 123 / 20%);
        text-align: center;
        line-height:30px;
        cursor: pointer;
    }
    .search-status{
        position: absolute;
        top: 15px;
        right: 70px;
        width: 150px;
    }
    .search-time{
        position: absolute;
        top: 15px;
        right: 235px;
    }
    .search-btn{
        position: absolute;
        top: 15px;
        right: 0;
    }
    .search-title{
        position: absolute;
        top: 15px;
        right: 650px;
    }
    .status1{color:green;}
    .status2{color:red;}
</style>