<template>
  <div class="left-panel">
    <div class="logo">文档纠错</div>
    <p class="username">【{{user.nickname}}】</p>
    <ul>
      <li :class="current_menu==1 ? 'on' : ''" @click="changeMenu(1)">
        <i class="el-icon-s-platform"></i>
        写作台
      </li>
      <li v-if="user.group_id==1" :class="current_menu==2 ? 'on' : ''" @click="changeMenu(2)">
        <i class="el-icon-s-promotion"></i>
        网站采集
      </li>
      <li :class="current_menu==3 ? 'on' : ''" @click="changeMenu(3)">
        <i class="el-icon-s-tools"></i>
        设置
      </li>
      <el-popconfirm 
        title="确定退出吗？"
        @confirm="logout"
      >
        <li slot="reference">
          <i class="el-icon-s-custom"></i>
          退出
        </li>
      </el-popconfirm>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    current_menu: { type: Number, default: () => 1 },
  },
  data() {
    return {
      user: [],
    }
  },
  mounted () {
    // 获取用户信息
    this.user = this.$lstore.getData('H5_CURRENT_USER')
  },
  methods: {
    // 切换菜单
    changeMenu(menu) {
      switch (menu) {
        case 1:
          this.$router.push('/')
          break;
        case 2:
          this.$router.push('/collect')
          break;
        case 3:
          this.$router.push('/setting')
          break;
      }
    },
    // 退出登录
    logout() {
      this.$lstore.removeData('H5_ACCESS_TOKEN')
      this.$lstore.removeData('H5_CURRENT_USER')
      this.$message.success("退出成功");
      this.$router.push('/signin')
    },
  }
}
</script>

<style scoped>
  .logo{
    color:#2051CA;
    font-size:16px;
    text-align: center;
    font-weight:bold;
    margin:30px 0;
  }
  .username{
    text-align: center;
    margin-bottom:20px;
    font-size:14px;
    color:#666;
    line-height:20px;
  }
  .username span{
    font-size:13px;
    padding-bottom:5px;
  }
  .left-panel{
    background-color: #fff;
    width: 100px;
    box-shadow: 1px 0 7px 0 rgb(0 0 0 / 8%);
    display:block;
    position: absolute;
    height:100%;
  }
  .left-panel ul{
    list-style: none;
  }
  .left-panel ul li{
    text-align: center;
    cursor: pointer;
    padding:15px 0;
    /* margin:15px 0; */
    font-size:14px;
    color:#A5ACB8;
  }
  .left-panel ul li.on{
    color:#2470ff;
    background: #f2f5fa;
  }
  .left-panel ul li i{
    display:block;
    font-size:30px;
  }
</style>
